import React from 'react';
import { graphql } from 'gatsby';
import { Tags } from 'mcc-cms';

const LayoutPage = ({data, pageContext}) => {
  return (
    <Tags
      data={data}
      pageContext={pageContext}
      padding="9rem 1.5rem 3rem 1.5rem"
    />
  )
}
export default LayoutPage

export const tagPageQuery = graphql`
  query TagPage($tag: String, $langKey: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(
      frontmatter: { key: { eq: "tags-list" } }
    ) {
      frontmatter {
        defaultLangKey
        tagList {
          tag
          featured
          overrideDefaultLangKey
          translations {
            langKey
            tagDisplay
            shortDesc
            longDesc
          }
          children
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___featuredPost], order: DESC }
      filter: { fields: {langKey: {eq: $langKey}, tags: {eq: $tag}} }
    ) {
      totalCount
      edges {
        node {
          fields {
            pathSlug
            langKey
          }
          frontmatter {
            title
            description
            createDate
            updateDate
            featuredPost
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
